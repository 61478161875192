import React from "react";
import {Box} from '@mui/material';

function PartnerLogos() {
    return (
        <Box sx={{backgroundColor: "#fff", paddingTop: "100px"}}>
            <Box mb={2} display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" justifyContent="center"
                 sx={{backgroundColor: "#f9f9f9"}}>
                <Box p={4}>
                    <span style={{fontWeight: 600, fontSize: 16}}>
                        Our partners
                    </span>
                </Box>
                <Box p={2}>
                    <a href="https://hilltopads.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        <img src="images/integration/hilltopads.svg" alt="HilltopAds" width="120"/>
                    </a>
                </Box>
                <Box p={2}>
                    <a href="https://pushground.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        <img src="images/partners/pushground.png" alt="Pushground" width="120"/>
                    </a>
                </Box>
                <Box p={2}>
                    <a href="https://mylead.global/en?utm_source=SKRO.eu&utm_medium=Baner&utm_campaign=Tracker-ofert&utm_content=EN"
                       target="_blank" rel="noreferrer">
                        <img src="images/partners/mylead.svg" alt="MyLead" width="90"/>
                    </a>
                </Box>
                <Box p={2}>
                    <a href="https://clickdealer.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        <img src="images/partners/clickdealer.svg" alt="ClickDealer" width="110"/>
                    </a>
                </Box>
                <Box p={2}>
                    <a href="https://mondiad.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        <img src="images/partners/mondiad.svg" alt="Mondiad" width="90"/>
                    </a>
                </Box>
                <Box p={2}>
                    <a href="https://clickadilla.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        <img src="images/partners/clickadilla.svg" alt="ClickAdilla" width="130"/>
                    </a>
                </Box>
            </Box>
        </Box>
    );
}

export default PartnerLogos;
