import React from "react";
import {Divider, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function HilltopAdsIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pt: 1, pb: 2}}>
                <a href="https://hilltopads.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/hilltopads.svg" alt="HilltopAds logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate HilltopAds with Skro?"/>
            <Box>
                <p>
                    <a href="https://hilltopads.com/?utm_source=skro.eu&utm_medium=partners_logo&utm_campaign=trackers&utm_content=en"
                       target="_blank" rel="noreferrer">
                        HilltopAds
                    </a> is a leading online advertising platform with billions of high-quality impressions,
                    performance-based targeting options and anti-fraud solutions that connects brands with their
                    potential customers from all over the world and helps publishers make money online.
                </p>
                <p>
                    Integration empowers you to track all your campaigns, zones, sites, ads, pubs etc. Additionally,
                    it provides a comprehensive overview of your costs and conversions.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>

                <h2>
                    Step 1: Create a HilltopAds traffic source and set up <strong>Postback URL</strong>
                </h2>
                <ul>
                    <li>Navigate to <strong>Traffic Sources</strong>.</li>
                    <li>Click on <strong>Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/hilltopads/hilltopads_1.jpg"
                          alt="How to set up HilltopAds traffic source on Skro?"
                />
                <ul>
                    <li>Click on <strong>HilltopAds</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/hilltopads/hilltopads_2.jpg"
                          alt="Choose HilltopAds on the list"
                />
                <ul>
                    <li>Traffic Source Postback URL is already prefilled for your.</li>
                </ul>
                <DocImage src="/images/docs/hilltopads/hilltopads_3.jpg"
                          alt="Set HilltopAds postback url"
                />
                <ul>
                    <li>All tracking parameters are prefilled for you.</li>
                </ul>
                <DocImage src="/images/docs/hilltopads/hilltopads_4.jpg"
                          alt="Set HilltopAds tracking parameters"
                />
                <ul>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default HilltopAdsIntegration;
