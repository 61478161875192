import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import {ListItemText, Box, ListItemButton, useTheme} from '@mui/material';
import randomID from "../../shared/functions/randomID";
import HowToIntegrateSkroWithShopifyStore from "./shopify/HowToIntegrateSkroWithShopifyStore";
import HowToCreateACampaignToTrackMyShopifyTraffic from "./shopify/HowToCreateACampaignToTrackMyShopifyTraffic";
import HowToCreateACampaignToTrackFacebookTraffic from "./facebook/HowToCreateACampaignToTrackFacebookTraffic";
import HowToIntegrateFacebookCAPI from "./facebook/HowToIntegrateFacebookCAPI";
import ClickBankIntegration from "./clickbank/ClickBankIntegration";
import ExoClickIntegration from "./exoclick/ExoClickIntegration";
import PropellerAdsIntegration from "./propellerads/PropellerAdsIntegration";
import PushgroundIntegration from "./pushground/PushgroundIntegration";
import MondiadIntegration from "./mondiad/MondiadIntegration";
import SedoIntegration from "./sedo/SedoIntegration";
import System1Integration from "./system1/System1Integration";
import GoogleAdsIntegration from "./googleads/GoogleAdsIntegration";
import MicrosoftAdsIntegration from "./microsoftads/MicrosoftAdsIntegration";
import BodisIntegration from "./bodis/BodisIntegration";
import TonicIntegration from "./tonic/TonicIntegration";
import TaboolaIntegration from "./taboola/TaboolaIntegration";
import MgidIntegration from "./mgid/MgidIntegration";
import FacebookAdsIntegration from "./facebook/FacebookAdsIntegration";
import OutbrainIntegration from "./outbrain/OutbrainIntegration";
import TikTokIntegration from "./tiktok/TikTokIntegration";
import SkroPostbackParameters from "./postback/SkroPostbackParameters";
import FacebookAdditionalConversionAPIParameters from "./facebook/FacebookAdditionalConversionAPIParameters";
import {styled} from "@mui/system";
import RsocSystem1Integration from "./rsoc-system1/RsocSystem1Integration";
import AboutRsoc from "./rsoc/AboutRsoc";
import SedoDirectIntegration from "./sedo/SedoDirectIntegration";
import AdsIntegration from "./ads/AdsIntegration";
import BuyGoodsIntegration from "./buygoods/BuyGoodsIntegration";
import MaxWebIntegration from "./maxweb/MaxWebIntegration";
import DomainActiveIntegration from "./domain-active/DomainActiveIntegration";
import AboutDeploySitesOnAws from "./websites/AboutDeploySitesOnAws";
import AWSAPIIntegration from "./websites/AWSAPIIntegration";
import WebsitesDomainSetup from "./websites/WebsitesDomainSetup";
import WebsitesDeploymentSetup from "./websites/WebsitesDeploymentSetup";
import RsocInuvoIntegration from "./rsoc-inuvo/RsocInuvoIntegration";
import CloudflareAPIIntegration from "./websites/CloudflareAPIIntegration";
import NamesiloAPIIntegration from "./websites/NamesiloAPIIntegration";
import PushHouseIntegration from "./pushhouse/PushHouseIntegration";
import HilltopAdsIntegration from "./hilltopads/HilltopAdsIntegration";

const CustomListItem = styled(ListItemButton)(({theme}) => {
    return ({
        "&:hover": {
            color: theme.palette.common.darkBlue,
            backgroundColor: theme.palette.primary.rowHover,
        },
        "&.Mui-selected": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            "&:hover": {
                backgroundColor: theme.palette.primary.rowHover,
                color: theme.palette.primary.dark,
            },
        },
    })
});

export default function TreeViewNav(props) {

    const theme = useTheme();

    const [selectedLink, setSelectedLink] = useState("");

    useEffect(() => {
        if (props?.location?.pathname) {
            setSelectedLink(props?.location?.pathname);
        }
    }, [props?.location?.pathname]);

    const scrollToTop = () => {
        const element = document.getElementById("docContent");
        element.scrollTo({top: 0, behavior: "smooth"});
    };

    const treeNodes = [
        {
            label: "Track Conversions",
            children: [
                {
                    id: randomID(8),
                    label: "Parameters in Postback URLs",
                    htmlTitle: "",
                    htmlDescription: "",
                    link: "/docs/postback/parameters-in-postback-urls",
                    component: <SkroPostbackParameters/>
                }
            ]
        },
        {
            label: "Shopify",
            children: [
                {
                    id: randomID(8),
                    label: "How to create a campaign to track my Shopify traffic?",
                    htmlTitle: "How to create a campaign in Skro tracker to track my Shopify traffic?",
                    htmlDescription: "If you’re looking for a tracking tool that will help you gain insight into your e-commerce business, Skro might just be a perfect solution for you. ",
                    link: "/docs/shopify/how-to-create-a-campaign-to-track-my-shopify-traffic",
                    component: <HowToCreateACampaignToTrackMyShopifyTraffic/>
                },
                {
                    id: randomID(8),
                    label: "How to integrate Skro with Shopify store?",
                    htmlTitle: "How to integrate Skro tracker with my Shopify store?",
                    htmlDescription: "Shopify is the leading cloud-based, multi-channel commerce platform. Merchants can use the software to design, set up, and manage their stores across multiple sales channels, including web, mobile, social media, marketplaces, brick-and-mortar locations and pop-up shops.",
                    link: "/docs/shopify/how-to-integrate-skro-with-my-shopify-store",
                    component: <HowToIntegrateSkroWithShopifyStore/>
                }
            ]
        },
        {
            label: "Facebook",
            children: [
                {
                    id: randomID(8),
                    label: "How to create a campaign to track Facebook traffic?",
                    htmlTitle: "How to create a campaign to track Facebook traffic?",
                    link: "/docs/facebook/how-to-create-a-campaign-to-track-facebook-traffic",
                    component: <HowToCreateACampaignToTrackFacebookTraffic/>
                },
                {
                    id: randomID(8),
                    label: "How to integrate and set up Facebook (Meta) Conversion API?",
                    htmlTitle: "How to integrate and set up Facebook (Meta) Conversion API?",
                    link: "/docs/facebook/how-to-integrate-facebook-capi",
                    component: <HowToIntegrateFacebookCAPI/>
                },
                {
                    id: randomID(8),
                    label: "How to send additional parameters via Facebook Conversion API?",
                    htmlTitle: "How to send additional parameters via Facebook Conversion API?",
                    link: "/docs/facebook/how-to-send-additional-parameters-via-facebook-meta-conversion-api",
                    component: <FacebookAdditionalConversionAPIParameters/>
                }
            ]
        },
        {
            label: "Affiliate Network Integrations",
            children: [
                {
                    id: randomID(8),
                    label: "BuyGoods Integration",
                    htmlTitle: "How to integrate BuyGoods with Skro tracker?",
                    htmlDescription: "BuyGoods is a global Internet Retailer, offering safe and reliable online shopping experience focused on customer's experience and service.",
                    link: "/docs/integrations/buygoods-integration",
                    component: <BuyGoodsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "ClickBank Integration",
                    htmlTitle: "How to integrate ClickBank with Skro tracker?",
                    htmlDescription: "ClickBank is one of the affiliate networks that has stood at the beginning of affiliate industry development in the internet. If you run the ad campaigns using a tracking solution such as Skro, you should be aware that regular postback tracking method is not applicable with this network.",
                    link: "/docs/integrations/clickbank-integration",
                    component: <ClickBankIntegration/>
                },
                {
                    id: randomID(8),
                    label: "MaxWeb Integration",
                    htmlTitle: "How to integrate MaxWeb with Skro tracker?",
                    htmlDescription: "MaxWeb is an affiliate network that operates in several verticals, such as nutra, ecommerce, and survival.",
                    link: "/docs/integrations/maxweb-integration",
                    component: <MaxWebIntegration/>
                },
            ]
        },
        {
            label: "Traffic Source Integrations",
            children: [
                {
                    id: randomID(8),
                    label: "Facebook (Meta) Ads Integration",
                    htmlTitle: "How to integrate Facebook (Meta) Ads with Skro tracker?",
                    htmlDescription: "Facebook (Meta) Ads integration gives you the ability to track, analyze, optimize and scale all your ads through the Skro UI.",
                    link: "/docs/integrations/facebook-meta-ads-integration",
                    component: <FacebookAdsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "TikTok Integration",
                    htmlTitle: "How to integrate TikTok with Skro tracker?",
                    htmlDescription: "TikTok integration gives you the ability to track, analyze, optimize and scale all your ads through the Skro UI.",
                    link: "/docs/integrations/tiktok-integration",
                    component: <TikTokIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Google Ads Integration",
                    htmlTitle: "How to integrate Google Ads with Skro tracker?",
                    htmlDescription: "Google Ads integration gives you the ability to track, analyze, optimize and scale all your ads through the Skro UI.",
                    link: "/docs/integrations/google-ads-integration",
                    component: <GoogleAdsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Microsoft (Bing) Ads Integration",
                    htmlTitle: "How to integrate Microsoft (Bing) Ads with Skro tracker?",
                    htmlDescription: "Microsoft (Bing) Ads integration gives you the ability to track, analyze, optimize and scale all your ads through the Skro UI.",
                    link: "/docs/integrations/microsoft-bing-ads-integration",
                    component: <MicrosoftAdsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "MGID Integration",
                    htmlTitle: "How to integrate MGID Ads with Skro tracker?",
                    htmlDescription: "MGID native advertising platform provides access to unique global audiences — always from premium, verified publishers, that are connected directly to our platform.",
                    link: "/docs/integrations/mgid-integration",
                    component: <MgidIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Outbrain Integration",
                    htmlTitle: "How to integrate Outbrain Ads with Skro tracker?",
                    htmlDescription: "Outbrain is the world’s leading discovery and native advertising feed for the open web. A third of the world’s Internet-connected population explores and discovers information through our feed technology, which is trusted by emerging to established brands and integrated into thousands of media companies’ tech stacks to manage and monetize their publishing operations.",
                    link: "/docs/integrations/outbrain-integration",
                    component: <OutbrainIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Taboola Integration",
                    htmlTitle: "How to integrate Taboola Ads with Skro tracker?",
                    htmlDescription: "Taboola is the world's leading discovery & native advertising platform that helps people explore what's interesting and new in the moment of next.",
                    link: "/docs/integrations/taboola-integration",
                    component: <TaboolaIntegration/>
                },
                {
                    id: randomID(8),
                    label: "ExoClick Integration",
                    htmlTitle: "How to integrate ExoClick Ads with Skro tracker?",
                    htmlDescription: "ExoClick is a Barcelona-based online advertising company, providing online advertising services to both advertisers and publishers all over the world via web, mobile, tablet and smart TV. ExoClick's customers include advertisers, advertising agencies, traffic distribution partners and publishers.",
                    link: "/docs/integrations/exoclick-integration",
                    component: <ExoClickIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Mondiad Integration",
                    htmlTitle: "How to integrate Mondiad Ads with Skro tracker?",
                    htmlDescription: "Mondiad is an ad network for push, native, and banner ads, across all countries, devices, and verticals.",
                    link: "/docs/integrations/mondiad-integration",
                    component: <MondiadIntegration/>
                },
                {
                    id: randomID(8),
                    label: "PropellerAds Integration",
                    htmlTitle: "How to integrate PropellerAds with Skro tracker?",
                    htmlDescription: "PropellerAds is display and mobile advertising platform, providing industry leading user acquisition and ad optimization solutions for performance marketers.",
                    link: "/docs/integrations/propellerads-integration",
                    component: <PropellerAdsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Pushground Integration",
                    htmlTitle: "How to integrate Pushground Ads with Skro tracker?",
                    htmlDescription: "Pushground is a self-service advertising network providing comprehensive advertising solutions for ad agencies, marketers and affiliates.",
                    link: "/docs/integrations/pushground-integration",
                    component: <PushgroundIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Push.House Integration",
                    htmlTitle: "How to integrate Push.House Ads with Skro tracker?",
                    htmlDescription: "Push.House is a trusted source of premium traffic for all advertising needs.",
                    link: "/docs/integrations/push-house-integration",
                    component: <PushHouseIntegration/>
                },
                {
                    id: randomID(8),
                    label: "HilltopAds Integration",
                    htmlTitle: "How to integrate HilltopAds with Skro tracker?",
                    htmlDescription: "HilltopAds is a leading online advertising platform with billions of high-quality impressions, performance-based targeting options and anti-fraud solutions that connects brands with their potential customers from all over the world and helps publishers make money online.",
                    link: "/docs/integrations/hilltopads-integration",
                    component: <HilltopAdsIntegration/>
                },
            ]
        },
        {
            label: "Search Feed Arbitrage Integrations",
            children: [
                {
                    id: randomID(8),
                    label: "Ads.com Integration",
                    htmlTitle: "How to integrate Ads.com with Skro tracker?",
                    htmlDescription: "Ads.com is designed to help domainers monetize, manage, and sell undeveloped domains using smart features designed to maximize revenue.",
                    link: "/docs/integrations/ads-com-integration",
                    component: <AdsIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Domain Active Integration",
                    htmlTitle: "How to integrate Domain Active with Skro tracker?",
                    htmlDescription: "Domain Active offer a technology that utilizes machine learning to drive maximum ROI for our partners as well as a support team that offers the best customer service in the industry.",
                    link: "/docs/integrations/domain-active-integration",
                    component: <DomainActiveIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Bodis Integration",
                    htmlTitle: "How to integrate Bodis with Skro tracker?",
                    htmlDescription: "Bodis is designed to help domainers monetize, manage, and sell undeveloped domains using smart features designed to maximize revenue.",
                    link: "/docs/integrations/bodis-integration",
                    component: <BodisIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Sedo Integration",
                    htmlTitle: "How to integrate Sedo with Skro tracker?",
                    htmlDescription: "Sedo stands for Search Engine for Domain Offers and their online platform currently provides not only a convenient searchable domain marketplace, but also extensive services for domain buyers and sellers: from domain parking to value appraisals all the way up to the brokerage and marketing of domains by our professional brokerage team.",
                    link: "/docs/integrations/sedo-integration",
                    component: <SedoIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Sedo (FB Direct) Integration",
                    htmlTitle: "How to integrate Sedo with FB direct tracking in Skro?",
                    htmlDescription: "Sedo stands for Search Engine for Domain Offers and their online platform currently provides not only a convenient searchable domain marketplace, but also extensive services for domain buyers and sellers: from domain parking to value appraisals all the way up to the brokerage and marketing of domains by our professional brokerage team.",
                    link: "/docs/integrations/sedo-facebook-direct-integration",
                    component: <SedoDirectIntegration/>
                },
                {
                    id: randomID(8),
                    label: "System1 Integration",
                    htmlTitle: "How to integrate System1 with Skro tracker?",
                    htmlDescription: "System1 is the most dynamic responsive acquisition marketing platform. Skro integration with System1 gives you the ability to sync earnings data that has been adjusted after different traffic sources.",
                    link: "/docs/integrations/system1-integration",
                    component: <System1Integration/>
                },
                {
                    id: randomID(8),
                    label: "Tonic Integration",
                    htmlTitle: "How to integrate Tonic with Skro tracker?",
                    htmlDescription: "Tonic offers you the proper tooling to efficiently monetize your Native, Social, Search, Display & Direct Website traffic with the world’s largest search feed.",
                    link: "/docs/integrations/tonic-integration",
                    component: <TonicIntegration/>
                },
            ]
        },
        {
            label: "RSOC Integrations",
            children: [
                {
                    id: randomID(8),
                    label: "About RSOC",
                    htmlTitle: "About RSOC",
                    htmlDescription: "",
                    link: "/docs/integrations/about-rsoc",
                    component: <AboutRsoc/>
                },
                {
                    id: randomID(8),
                    label: "RSOC Inuvo Integration",
                    htmlTitle: "How to integrate RSOC Inuvo with Skro tracker?",
                    htmlDescription: "Inuvo is the most dynamic responsive acquisition marketing platform. Skro integration with RSOC Inuvo reports gives you the ability to sync earnings data that has been adjusted after different traffic sources.",
                    link: "/docs/integrations/inuvo-rsoc-integration",
                    component: <RsocInuvoIntegration/>
                },
                {
                    id: randomID(8),
                    label: "RSOC System1 Integration",
                    htmlTitle: "How to integrate RSOC System1 with Skro tracker?",
                    htmlDescription: "System1 is the most dynamic responsive acquisition marketing platform. Skro integration with RSOC System1 API gives you the ability to sync earnings data that has been adjusted after different traffic sources.",
                    link: "/docs/integrations/system1-rsoc-integration",
                    component: <RsocSystem1Integration/>
                }
            ]
        },
        {
            label: "Deploy Websites",
            children: [
                {
                    id: randomID(8),
                    label: "About AWS Deployment",
                    htmlTitle: "About AWS Deployment",
                    htmlDescription: "",
                    link: "/docs/deploy-website/about-aws-deployment",
                    component: <AboutDeploySitesOnAws/>
                },
                {
                    id: randomID(8),
                    label: "AWS Integration",
                    htmlTitle: "AWS API integration for static websites",
                    htmlDescription: "Why AWS S3 + CloudFront + Certificate Manager is Ideal for Hosting Static Websites?",
                    link: "/docs/deploy-website/aws-api-integration",
                    component: <AWSAPIIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Cloudflare Integration",
                    htmlTitle: "Cloudflare API integration for domain auto verification and distribution",
                    htmlDescription: "Cloudflare API integration for domain auto verification and distribution through Skro.",
                    link: "/docs/deploy-website/cloudflare-integration",
                    component: <CloudflareAPIIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Namesilo Integration",
                    htmlTitle: "Namesilo API integration for domain auto verification and distribution",
                    htmlDescription: "Namesilo API integration for domain auto verification and distribution through Skro.",
                    link: "/docs/deploy-website/namesilo-integration",
                    component: <NamesiloAPIIntegration/>
                },
                {
                    id: randomID(8),
                    label: "Website Domain Setup",
                    htmlTitle: "How to set up a domain to get SSL certificate?",
                    htmlDescription: "",
                    link: "/docs/deploy-website/how-to-setup-domain-to-get-ssl-certificate",
                    component: <WebsitesDomainSetup/>
                },
                {
                    id: randomID(8),
                    label: "Website Deployment Setup",
                    htmlTitle: "How to deploy website through Skro to AWS?",
                    htmlDescription: "",
                    link: "/docs/deploy-website/how-to-deploy-website",
                    component: <WebsitesDeploymentSetup/>
                }
            ]
        }
    ];

    const renderTreeItems = (treeNodes) => {
        return (
            treeNodes.map((nodes, index) => {
                return (
                    <Box key={index}>
                        <h3 style={{fontSize: 16, paddingLeft: 8}}>{nodes?.label}</h3>
                        {nodes?.children?.map((pool) => {
                            return (
                                <CustomListItem
                                    sx={{pt: 0, pb: 0}}
                                    key={pool?.id}
                                    component={NavLink} to={pool?.link}
                                    onClick={scrollToTop}
                                    selected={selectedLink === pool?.link}
                                >
                                    <ListItemText disableTypography sx={{fontSize: 14}} primary={pool?.label}
                                                  onClick={() => setSelectedLink(pool?.link)}/>
                                </CustomListItem>);
                        })}
                    </Box>
                );
            })
        );
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                justifyContent="center"
                sx={{
                    flexWrap: "noWrap",
                    [theme.breakpoints.down(768)]: {
                        flexWrap: "wrap",
                    },
                }}
            >
                <Box
                    mr={4}
                    alignItems="stretch"
                    sx={{
                        marginTop: "65px",
                        paddingBottom: "65px",
                        width: "100%",
                        maxWidth: 280,
                        overflow: "auto",
                        height: "100%",
                        maxHeight: "calc(var(--app-height) - 65px)",
                        backgroundColor: "#f9f9f9",
                        [theme.breakpoints.down(768)]: {
                            maxHeight: 300
                        },
                    }}
                >
                    <Box>
                        {renderTreeItems(treeNodes)}
                    </Box>
                </Box>
                <Box pl={3} pt={3} pb={3} id="docContent" alignItems="stretch"
                     sx={{
                         paddingLeft: "15px",
                         paddingRight: "15px",
                         marginTop: "65px",
                         width: "100%",
                         overflow: "auto",
                         height: "calc(var(--app-height) - 65px)",
                         [theme.breakpoints.down(768)]: {
                             height: "100%"
                         },
                         fontSize: 15
                     }}
                >
                    {treeNodes.map((item) => {
                        return item.children.map((child) => {
                            if (child.link === window.location.pathname) {
                                document.title = child.htmlTitle + " | Skro";
                                document.head.getElementsByTagName('meta')["description"].content = child?.htmlDescription;
                                return (
                                    <Box key={child.id}>
                                        {child.component}
                                    </Box>
                                );
                            }
                            return null;
                        })
                    })}
                </Box>
            </Box>
        </>
    );
}
