import React from "react";
import {Divider, Box} from "@mui/material";
import {DocHeaderTitle, MoreQuestionsFooterText} from "../shopify/HowToIntegrateSkroWithShopifyStore";
import {DocImage, DocRoot} from "../Docs";

function PushHouseIntegration() {
    return (
        <DocRoot>
            <Box sx={{textAlign: "center", pt: 1, pb: 2}}>
                <a href="https://push.house" target="_blank" rel="noreferrer">
                    <DocImage sx={{maxWidth: 200}} src="/images/integration/pushhouse.png" alt="Push.House logo"/>
                </a>
            </Box>
            <DocHeaderTitle title="How to integrate Push.House with Skro?"/>
            <Box>
                <p>
                    <a href="https://push.house"
                       target="_blank" rel="noreferrer">
                    Push.House
                    </a> is a trusted source of premium traffic for all advertising needs. Integration empowers you
                    to track all your campaigns, sites and feeds. Additionally, it provides a comprehensive overview
                    of your costs and conversions.
                </p>

                <Divider sx={{mt: 3, mb: 2}}/>

                <h2>
                    Step 1: Create a Push.House traffic source and set up <strong>Postback URL</strong>
                </h2>
                <ul>
                    <li>Navigate to <strong>Traffic Sources</strong>.</li>
                    <li>Click on <strong>Create</strong> button.</li>
                </ul>
                <DocImage src="/images/docs/pushhouse/push_house_1.jpg" alt="Setup Push.House traffic source step 1"
                />
                <ul>
                    <li>Click on <strong>Push.House</strong> logo.</li>
                </ul>
                <DocImage src="/images/docs/pushhouse/push_house_2.jpg"
                          alt="Setup Push.House traffic source step 2"
                />
                <ul>
                    <li>Traffic Source Postback URL is already prefilled for your.</li>
                </ul>
                <DocImage src="/images/docs/pushhouse/push_house_3.jpg"
                          alt="Setup Push.House traffic source step 3"
                />
                <ul>
                    <li>All tracking parameters are prefilled for you.</li>
                </ul>
                <DocImage src="/images/docs/pushhouse/push_house_4.jpg"
                          alt="Setup Push.House traffic source step 4"
                />
                <ul>
                    <li>Click on <strong>Save & Close</strong> button.</li>
                </ul>

                <p>Integration setup is done and your are ready to create a campaign.</p>
            </Box>
            <MoreQuestionsFooterText/>
        </DocRoot>
    );
}

export default PushHouseIntegration;
