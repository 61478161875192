import React, {useCallback, useEffect, useState} from "react";
import AOS from "aos/dist/aos";
import NavBar from "./navigation/NavBar";
import Footer from "./footer/Footer";
import CookieRulesDialog from "./cookies/CookieRulesDialog";
import CookieConsent from "./cookies/CookieConsent";
import Routing from "./Routing";
import smoothScrollTop from "../shared/functions/smoothScrollTop";
import {Box} from "@mui/material";

function Main() {
    const [selectedTab, setSelectedTab] = useState(null);
    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);
    const [isCookieRulesDialogOpen, setIsCookieRulesDialogOpen] = useState(false);

    useEffect(() => {
        AOS.init({once: true});
    }, []);

    const selectHome = useCallback(() => {
        smoothScrollTop();
        document.title = "Ad Tracker & Conversion Attribution Platform for Media Buyers and Affiliate Marketers | Skro";
        document.head.getElementsByTagName('meta')["description"].content = "Skro is an ad tracking solution based on 1st party cookies. Track & attribute conversions, revenue, and events in one place.";
        setSelectedTab("Home");
    }, [setSelectedTab]);

    const selectPricing = useCallback(() => {
        smoothScrollTop();
        document.title = "Plans & Pricing | Skro";
        document.head.getElementsByTagName('meta')["description"].content = "Try our free subscription plan with 250k free events per month. Sign up today!";
        setSelectedTab("Pricing");
    }, [setSelectedTab]);

    const selectFeatures = useCallback(() => {
        smoothScrollTop();
        document.title = "Tracking & Attribution Features | Skro";
        document.head.getElementsByTagName('meta')["description"].content = "Discover our ultimate solutions and features to skyrocket your online ad campaigns with hyper-accurate aggregated reports and integrations.";
        setSelectedTab("Features");
    }, [setSelectedTab]);

    const selectTermsConditions = useCallback(() => {
        smoothScrollTop();
        document.title = "Terms & Conditions | Skro";
        setSelectedTab("Terms & Conditions");
    }, [setSelectedTab]);

    const selectPrivacyPolicy = useCallback(() => {
        smoothScrollTop();
        document.title = "Privacy Policy | Skro";
        setSelectedTab("Privacy Policy");
    }, [setSelectedTab]);

    const selectEndUserPrivacyPolicy = useCallback(() => {
        smoothScrollTop();
        document.title = "End User Privacy Policy | Skro";
        setSelectedTab("End User Privacy Policy");
    }, [setSelectedTab]);

    const selectFAQ = useCallback(() => {
        smoothScrollTop();
        document.title = "FAQ | Skro";
        document.head.getElementsByTagName('meta')["description"].content = "Unlock answers to commonly asked questions with our Frequently Asked Questions (FAQ) section.";
        setSelectedTab("Frequently Asked Questions");
    }, [setSelectedTab]);

    const handleMobileDrawerOpen = useCallback(() => {
        setIsMobileDrawerOpen(true);
    }, [setIsMobileDrawerOpen]);

    const handleMobileDrawerClose = useCallback(() => {
        setIsMobileDrawerOpen(false);
    }, [setIsMobileDrawerOpen]);

    const handleCookieRulesDialogOpen = useCallback(() => {
        setIsCookieRulesDialogOpen(true);
    }, [setIsCookieRulesDialogOpen]);

    const handleCookieRulesDialogClose = useCallback(() => {
        setIsCookieRulesDialogOpen(false);
    }, [setIsCookieRulesDialogOpen]);

    const selectDocs = useCallback(() => {
        document.title = "Documentation & User Guides | Skro";
        document.head.getElementsByTagName('meta')["description"].content = "Discover comprehensive user guides for setting up your campaigns and integrations seamlessly. Explore step-by-step instructions to optimize your experience and ensure successful implementation.";
        setSelectedTab("Documentation");
    }, [setSelectedTab]);

    return (
        <Box sx={{backgroundColor: "common.white", overflowX: "hidden"}}>
            {!isCookieRulesDialogOpen && (
                <CookieConsent
                    handleCookieRulesDialogOpen={handleCookieRulesDialogOpen}
                />
            )}
            <CookieRulesDialog
                open={isCookieRulesDialogOpen}
                onClose={handleCookieRulesDialogClose}
            />
            <NavBar
                selectedTab={selectedTab}
                selectTab={setSelectedTab}
                mobileDrawerOpen={isMobileDrawerOpen}
                handleMobileDrawerOpen={handleMobileDrawerOpen}
                handleMobileDrawerClose={handleMobileDrawerClose}
            />
            <Routing
                selectHome={selectHome}
                selectPricing={selectPricing}
                selectFeatures={selectFeatures}
                selectTermsConditions={selectTermsConditions}
                selectPrivacyPolicy={selectPrivacyPolicy}
                selectEndUserPrivacyPolicy={selectEndUserPrivacyPolicy}
                selectFAQ={selectFAQ}
                selectDocs={selectDocs}
            />
            {selectedTab !== "Documentation" && <Footer/>}
        </Box>
    );
}

export default Main;
